* {
  margin: 0;
  box-sizing: border-box;
}

.portfolio-container {
  margin: 0 5%;
  margin-top: 15vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5%;
  padding: 12px 20px;
}

.section-card {
  background-size: cover;
  background-position: center center;
  min-height: 70vh;
  overflow: hidden;
  font-weight: bold;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.section-card:hover {
  filter: opacity(0.8);
}

.section-title {
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: rgb(185, 185, 185, 0.1);
  backdrop-filter: blur(3px);
}

.section-title h3 {
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
  font-size: 4.5rem;
  text-shadow: black 0.1em 0.1em 0.2em;
}

@media (max-width: 770px) {
  .portfolio-container {
    grid-template-columns: repeat(1, 1fr);
    /* padding: 0; */
    /* margin: 0; */
    margin-top: 10vh;
    gap: 0px;
  }

  .section-card {
    /* border-radius: 0px; */
    min-height: 40vh;
  }

  .section-title h3 {
    font-size: 3rem;
  }
}
