* {
  margin: 0;
  box-sizing: border-box;
}

.video-container {
  width: auto;
  margin: 0px 15%;
  margin-top: 10vh;
  height: 55vh;
}

.video-gallery-container {
  width: auto;
  margin: 0px 15%;
  height: auto;
  background-color: #1f242d;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-btn {
  margin: auto;
  color: black;
  background-color: #1f242d;
  width: 25%;
  outline: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.video-btn:hover {
  /* background-color: rgba(228, 50, 213, 0.925); */
  background: rgb(185, 185, 185, 0.2);
  backdrop-filter: blur(3px);
}

.video-btn:focus {
  background-color: rgba(228, 50, 213, 0.925);
}

.video-img {
  /* padding: 0 15%; */
  margin: 0 10%;
  padding: 10px 0;
  width: auto;
  height: auto;
  border-radius: 15px;
}

.video-img-text {
  margin-top: 5px;
  text-align: center;
  color: white;
}

@media (max-width: 767px) {
  .video-container {
    height: 35vh;
    margin: 0;
    margin-top: 10vh;
  }

  .video-gallery-container {
    height: auto;
    margin: 0;
  }

  .react-player {
    margin-bottom: 15px;
  }
}

@media (max-width: 500px) {
  .video-gallery-container {
    height: auto;
    font-size: 10px;
  }
}
