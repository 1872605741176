* {
  margin: 0;
  box-sizing: border-box;
}

.artists-container {
  margin-top: 10vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 12px 20px;
}

.artist-card {
  background-size: cover;
  background-position: center center;
  min-height: 500px;
  overflow: hidden;
  font-weight: bold;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.artist-card:hover {
  filter: opacity(0.8);
}

.artist-title {
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: rgb(185, 185, 185, 0.1);
  backdrop-filter: blur(3px);
}

.artist-title h3 {
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
  font-size: 3rem;
  text-shadow: black 0.1em 0.1em 0.2em;
  text-align: center;
}

@media (max-width: 1200px) {
  .artists-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) {
  .artists-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .artists-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .artist-card {
    min-height: 50vh;
  }

  .artist-title h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 520px) {
  .artists-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .artist-card {
    min-height: 35vh;
  }
}

@media (max-width: 400px) {
  .artists-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    padding: 12px 10%;
  }

  .artist-card {
    min-height: 50vh;
  }

  .artist-title h3 {
    font-size: 2.5rem;
  }
}
