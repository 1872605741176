* {
  margin: 0;
  box-sizing: border-box;
}

.gallery-container {
  margin-top: 10vh;
  width: 100%;
}

.gallery-row {
  column-count: 4;
  column-span: all;
}

.gallery-column img {
  width: 100%;
  padding: 12px 5px;
  border-radius: 40px;
}

.gallery-column img:hover {
  filter: opacity(0.8);
}

/*ESTILOS DEL MODAL*/

.modal-img {
  display: none;
}

.modal-img-open {
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: animation 0.8s;
}

.content-img {
  margin-top: 10vh;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-img a {
  color: #fff;
  font-size: 40px;
  text-decoration: none;
  margin: 0 10px;
}

.content-img a:nth-child(2) {
  margin: 0;
  height: 100%;
  flex-shrink: 2;
}

.content-img img {
  width: auto;
  height: 100%;
  max-width: 100%;
  border: 7p solid #fff;
  box-sizing: border-box;
}

.modal-close {
  display: block;
  background: #fff;
  width: 30px;
  height: 30px;
  margin: 15px auto;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  color: #000;
  padding: 2px 0;
  border-radius: 50%;
}

.content-img button {
  width: 60px;
  height: 60px;
  background: none;
  border: none;
  color: #fff;
  margin: 10px;
  box-shadow: none;
}

.content-img button:hover {
  opacity: 0.6;
}

.content-img svg {
  font-size: 2.5em;
}

@keyframes animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .gallery-row {
    column-count: 2;
  }

  .content-img {
    width: 80%;
    height: 30vh;
    margin-left: 10%;
    margin-top: 30vh;
    display: none;
  }

  .modal-img-open {
    display: none;
  }
}

@media (max-width: 600px) {
  .gallery-row {
    column-count: 1;
  }

  .gallery-column img:hover {
    filter: none;
  }
}
