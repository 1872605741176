* {
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

:root {
  --bg-color: #1f242d;
  --second-bg-color: #323946;
  --text-color: #fff;
  --main-color: #0ef;
}

html {
  font-size: 63.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.logo {
  font-size: 2.5rem;
  color: var(--text-color);
  font-weight: 600;
  cursor: default;
}

.navbar a {
  font-size: 1.7rem;
  color: var(--text-color);
  margin-left: 4rem;
}

.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  height: 90vh;
}

.home-img {
  width: 40vw;
  margin-left: 10vw;
}

.home-content {
  width: 40vw;
  margin-right: 10vw;
}

.home-img img {
  width: 100%;
}

.description {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.description-item {
  padding: 10px;
  width: 50%;
  display: flex;
}

.item_icon {
  width: 20%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.item_icon svg {
  color: var(--main-color);
  width: auto;
  height: 40px;
}

.item_title {
  width: 80%;
  display: flex;
  margin-left: 10px;
}

.item_title h1 {
  font-size: 2.2rem;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.item_title h2 {
  font-size: 2.2rem;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.title {
  font-size: 4.6rem;
  font-weight: 500;
  line-height: 1.3;
}

/* COLOR-CORRECTION */
.content-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 50px;
}

.section_color h2 {
  padding: 50px 0;
  font-size: 4.6rem;
  text-align: center;
  margin-bottom: 25px;
}
.section_color_items {
  margin: 0 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5%;
  padding: 12px 20px;
}
.section-color-card {
  background-size: cover;
  background-position: center center;
  min-height: 60vh;
  overflow: hidden;
  font-weight: bold;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}
.section-color-card:hover {
  filter: opacity(0.8);
}

/* FOOTER */

.myFooter {
  background: var(--bg-color);
  width: 100%;
  height: 10vh;
  text-align: center;
  white-space: nowrap;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerItem {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}

.footerItem:hover {
  color: var(--main-color);
}

.footerItem:active {
  color: var(--main-color);
}

.footerItem span {
  margin-left: 10px;
}

@media screen and (max-width: 1260px) {
  .content {
    width: 100%;
    margin: 0 5%;
  }

  .home-img {
    width: 380px;
    height: 380px;
  }

  .item_title h1 {
    font-size: 2rem;
  }

  .item_title h2 {
    font-size: 2rem;
  }

  .title {
    font-size: 30px;
  }

  .item_icon svg {
    font-size: 35px;
  }

  .content-item {
    display: block;
  }

  .section_color_items {
    margin: 0 20%;
  }
}

@media screen and (max-width: 980px) {
  .content {
    width: 100%;
    margin: 0;
  }

  .home-img {
    width: 340px;
    height: 340px;
  }

  .home-img {
    justify-content: center;
  }

  .home-content {
    width: 380px;
  }

  .title {
    font-size: 25px;
  }

  .home-content svg {
    font-size: 30px;
  }
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 35px;
  }

  .myFooter {
    font-size: small;
  }
}

@media screen and (max-width: 800px) {
  .home {
    display: block;
    width: 100%;
    justify-content: center;
    margin: 0;
    margin-top: 10vh;
    height: auto;
  }

  .home-img {
    padding: 10px;
    height: 45vh;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
    width: 70%;
  }

  .home-img img {
    width: 100%;
    height: auto;
  }

  .home-content {
    padding: 50px;
    padding-top: 0;
    width: 100%;
    height: auto;
  }

  .description {
    width: 100%;
    display: block;
  }

  .title {
    text-align: center;
    font-size: 36px;
    margin-top: 40px;
  }

  .description-item {
    width: 100%;
    display: block;
    margin: 0;
    padding: 15px 0;
  }

  .item_icon {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .item_title {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .item_title h1 {
    font-size: 25px;
  }

  .item_title h2 {
    font-size: 25px;
  }

  .section_color_items {
    margin: 0 20%;
    display: block;
    gap: 0;
    padding: 0;
  }

  .section-color-card {
    min-height: 70vh;
  }
}

@media screen and (max-width: 500px) {
  .home {
    width: 100%;
    margin: 0;
    margin-top: 10vh;
  }

  .home-img {
    height: 45vh;
    width: 100%;
  }

  .home-img img {
    height: auto;
    width: 100%;
  }

  .title {
    text-align: center;
    font-size: 28px;
  }

  .description-item {
    width: 100%;
    display: block;
    margin: 0;
    padding: 15px 0;
  }

  .item_icon {
    width: 100%;
    text-align: center;
    padding: 5px 0;
  }

  .item_icon svg {
    height: 30px;
    width: 30px;
  }

  .item_title {
    width: 100%;
    text-align: center;
  }

  .item_title h1 {
    font-size: 18px;
  }

  .item_title h2 {
    font-size: 18px;
  }

  .title {
    font-size: 32px;
  }

  .section_color_items {
    margin: 0 10%;
  }

  .section-color-card {
    min-height: 60vh;
  }

  /* .myFooter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    padding: 0;
  }

  .footerItem {
    justify-content: center;
  } */

  .myFooter {
    display: block;
    margin: 0;
    padding: 20px;
    height: auto;
  }

  .footerItem {
    justify-content: center;
  }
}
