* {
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat';
  font-size: 1.6rem;
}

a {
  text-decoration: none;
}

.nav {
  top: 0;
  z-index: 999;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 10vh;
  background: #1f242d;
}

/* .title {
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
  margin-left: 70px;
  font-size: 1.125em;
} */

.nav_logo {
  width: 160px;
  height: auto;
  margin-left: 70px;
}

.nav_logo img {
  height: 100%;
  width: 100%;
}

.nav_menu {
  margin-bottom: 0;
  margin-right: 70px;
  margin-left: auto;
  display: flex;
  gap: 3rem;
}

.nav_item {
  text-decoration: none;
  list-style: none;
}

.nav_link {
  font-family: 'Montserrat';
  text-decoration: none;
  color: white;
  font-size: 12px;
  background: none;
  border: none;
}

.nav button {
  margin: 0;
  padding: 0;
}

.arrow::after {
  content: '';
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: auto;
  left: auto;
  font-size: 0.875rem;
  padding: 0 01.5rem;
  list-style: none;
  border-radius: 0.5rem;
  background-color: #080808;
  box-shadow: 0.8;
  display: none;
  margin-top: 0.5rem;
  margin-left: -20px;
}

.dropdown.show {
  display: block;
}

.nav-submenu-item {
  padding: 0.8rem 0;
  text-align: center;
}

.nav_link:hover {
  color: #0ef;
}

.nav_link_active {
  font-family: 'Montserrat';
  text-decoration: none;
  color: white;
  font-size: 12px;
  border-bottom: 5px solid transparent;
  /* border-color: #c48f56; */
  border-color: #0ef;
}

.nav_link_active:hover {
  color: #0ef;
}

.nav_toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: white;
  transition: 0.4s ease-in;
}

.menuItem {
  position: relative;
  z-index: 4;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 768px) {
  .menuItem {
    color: white;
    font-size: 1.5em;
    display: block;
    margin-right: 20px;
  }

  .nav_menu {
    position: absolute;
    top: 0;
    padding-left: 30px;
    padding-top: 15vh;
    right: 0;
    margin: 0;
    width: 40%;
    height: 100vh;
    background: #1f242d;
    flex-direction: column;
    align-items: flex-start;
    transform: translate(100%);
    transition: 0.5s ease-in;
    justify-content: start;
    z-index: 2;
  }

  .nav_logo {
    width: 120px;
    margin-left: 20px;
  }

  .nav {
    position: fixed;
    top: 0;
    justify-content: space-between;
    z-index: 999;
  }

  .nav_link_active {
    font-family: 'Montserrat';
    text-decoration: none;
    color: white;
    font-size: 12px;
    border: 1px solid transparent;
    border-right: none;
    /* border-color: #c48f56; */
    border-color: #0ef;
    padding: 7px;
  }

  .dropdown {
    position: relative;
    background-color: black;
    margin-left: -10px;
  }
}

.nav_active {
  transform: translateX(0);
}
